
















import { Component, Prop, Watch } from 'vue-property-decorator'
import Base from '@/application/models/Base'
import PatientVitalsSources from '@/application/models/PatientVitalsSources'
import { Action, Getter } from 'vuex-class'
import Patient from '@/application/models/Patient'

@Component({})
export default class DeviceInfo extends Base {
  @Prop({ required: true })
  patient!: Patient

  @Action('FETCH_PATIENTVITALSSOURCES_DATA', { namespace: 'vitalSources' })
  readonly fetchPatientvitalssourcesData!: (patientID: string) => Promise<Array<PatientVitalsSources>>

  patientVitalsSources: Array<PatientVitalsSources> = []

  constructor () {
    super('device-info')
  }

  mounted () {
    this.fetchPatientvitalssourcesData(this.patient.id!).then((patientVitalsSources: Array<PatientVitalsSources>) => {
      console.log('fetchPatientvitalssourcesData ', patientVitalsSources)
      this.patientVitalsSources = patientVitalsSources
    })
  }

  @Watch('patient')
  onPatientChange () {
    this.fetchPatientvitalssourcesData(this.patient.id!).then((patientVitalsSources: Array<PatientVitalsSources>) => {
      console.log('fetchPatientvitalssourcesData ', patientVitalsSources)
      this.patientVitalsSources = patientVitalsSources
    })
  }
}
