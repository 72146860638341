


















import { Component, Vue, Prop } from 'vue-property-decorator'
import { Color } from 'vuetify/lib/util/colors'

@Component({})
export default class SimpleGraph extends Vue {
  @Prop({ required: true })
  listOfNumbers!: Array<number> | Array<string>

  @Prop({ required: true })
  color!: string | Color
}
