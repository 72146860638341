





















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Color } from 'vuetify/lib/util/colors'
import { VitalSignType } from '@/application/models/Thresholds'

@Component({})
export default class RealNumber extends Vue {
  @Prop({ required: true })
  public visualNumber!: number

  @Prop({ required: true })
  public text!: string

  @Prop({ required: true })
  public icon!: string

  @Prop({ required: true })
  color!: string | Color

  @Prop({ required: false })
  type?: 'puls' | 'oxygen' | 'temp'

  get getClasses (): string {
    let className = 'big-number '
    switch (this.type) {
      case 'oxygen':
        if (this.$store.state.thresholds.isReachedThreshold(VitalSignType.OXYGEN_SATURATION, this.visualNumber)) {
          className += 'threshold-warning blink'
        }
        break
      case 'puls':
        if (this.$store.state.thresholds.isReachedThreshold(VitalSignType.PULSE_RATE, this.visualNumber)) {
          className += 'threshold-warning blink'
        }
        break
      case 'temp':
        if (this.$store.state.thresholds.isReachedThreshold(VitalSignType.BODY_TEMPERATURE, this.visualNumber)) {
          className += 'threshold-warning blink'
        }
        break
    }
    return className
  }
}
