








import Base from '../../../application/models/Base'
import { Component, Prop } from 'vue-property-decorator'
import ApexCharts from 'apexcharts'
import { Getter } from 'vuex-class'
import { Threshold, VitalSignType } from '@/application/models/Thresholds'
import AppSetting from '@/application/models/AppSetting'

@Component({})
export default class PrettyChart extends Base {
  // constanten
  private readonly TICKINTERVAL = 86400000
  private readonly XAXISRANGE = 777600000

  private lastDate = 0
  private data: Array<{ x: number; y: number }> = []

  // Input Var
  @Prop({ required: true })
  type!: VitalSignType.PULSE_RATE | VitalSignType.OXYGEN_SATURATION | VitalSignType.BODY_TEMPERATURE

  constructor () {
    super('')
  }

  @Getter('appSetting', { namespace: 'appSetting' })
  appSetting!: AppSetting

  @Getter('thresholds', { namespace: 'thresholds' })
  threshholdStored!: Array<Threshold>

  public series = [{
    data: this.data.slice()
  }]

  public chartOptions = {
    chart: {
      id: 'realtime',
      height: 350,
      type: 'line',
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          speed: 1000
        }
      },
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    markers: {
      size: 0
    },
    xaxis: {
      type: 'datetime',
      range: this.XAXISRANGE,
      labels: {
        show: false
      }
    },
    yaxis: {},
    legend: {
      show: false
    },
    annotations: {
      yaxis: [
        {
          y: 10,
          y2: 0,
          borderColor: '#E57373',
          fillColor: '#E57373'
        },
        {
          y: 200,
          y2: 90,
          borderColor: '#E57373',
          fillColor: '#E57373'
        }
      ]
    }
  }

  get yAxis () {
    let yAxis = this.chartOptions.yaxis

    const pulse = this.threshholdStored.find(vital => vital.vitalSignType === VitalSignType.PULSE_RATE)
    const oxygen = this.threshholdStored.find(vital => vital.vitalSignType === VitalSignType.OXYGEN_SATURATION)
    const temperature = this.threshholdStored.find(vital => vital.vitalSignType === VitalSignType.BODY_TEMPERATURE)

    if (pulse && this.type === VitalSignType.PULSE_RATE) {
      yAxis = { y: pulse.minValue - 10, y2: pulse.maxValue * 1.5 }
    } else if (oxygen && this.type === VitalSignType.OXYGEN_SATURATION) {
      yAxis = { y: oxygen.minValue - 2, y2: oxygen.maxValue + 2 }
    } else if (temperature && this.type === VitalSignType.BODY_TEMPERATURE) {
      yAxis = { y: temperature.minValue - 2, y2: temperature.maxValue + 2 }
    }
    this.chartOptions.yaxis = this.appSetting.fixYAxis ? yAxis : {}
    return this.chartOptions.yaxis
  }

  get threshholds () {
    let y = this.chartOptions.annotations.yaxis[0].y
    let y2 = this.chartOptions.annotations.yaxis[1].y2

    const pulse = this.threshholdStored.find(vital => vital.vitalSignType === VitalSignType.PULSE_RATE)
    const oxygen = this.threshholdStored.find(vital => vital.vitalSignType === VitalSignType.OXYGEN_SATURATION)
    const temperature = this.threshholdStored.find(vital => vital.vitalSignType === VitalSignType.BODY_TEMPERATURE)

    if (pulse && this.type === VitalSignType.PULSE_RATE) {
      y = pulse.minValue
      y2 = pulse.maxValue
    } else if (oxygen && this.type === VitalSignType.OXYGEN_SATURATION) {
      y = oxygen.minValue
      y2 = oxygen.maxValue
    } else if (temperature && this.type === VitalSignType.BODY_TEMPERATURE) {
      y = temperature.minValue
      y2 = temperature.maxValue
    }

    this.chartOptions.annotations.yaxis[0].y = y
    this.chartOptions.annotations.yaxis[1].y2 = y2
    return {
      y: y,
      y2: y2
    }
  }

  mounted () {
    const chart: ApexCharts = (this.$refs.chart as unknown as ApexCharts)
    window.setInterval(() => {
      this.getNewSeries(this.lastDate, {
        min: 10,
        max: 90
      })

      chart.updateSeries([{
        data: this.data
      }])
    }, 1000)

    // every 60 seconds, we reset the data to prevent memory leaks
    window.setInterval(() => {
      this.resetData()

      chart.updateSeries([{
        data: this.data
      }], false)
    }, 60000)
  }

  public resetData () {
    // Alternatively, you can also reset the data at certain intervals to prevent creating a huge series
    this.data = this.data.slice(this.data.length - 10, this.data.length)
  }

  public getNewSeries (baseval: number, yrange: Interval) {
    const newDate = baseval + this.TICKINTERVAL
    this.lastDate = newDate

    for (let i = 0; i < this.data.length - 10; i++) {
      // IMPORTANT
      // we reset the x and y of the data which is out of drawing area
      // to prevent memory leaks
      this.data[i].x = newDate - this.XAXISRANGE - this.TICKINTERVAL
      this.data[i].y = 0
    }

    this.data.push({
      x: newDate,
      y: this.getValueByType().value
    })
  }

  private getValueByType () {
    switch (this.type) {
      case VitalSignType.PULSE_RATE:
        return this.$store.state.vitals.pulseRateVitals[this.$store.state.vitals.pulseRateVitals.length - 1]
      case VitalSignType.OXYGEN_SATURATION:
        return this.$store.state.vitals.oxygenSaturationVitals[this.$store.state.vitals.oxygenSaturationVitals.length - 1]
      case VitalSignType.BODY_TEMPERATURE:
        return this.$store.state.vitals.bodyTemperatureVitals[this.$store.state.vitals.bodyTemperatureVitals.length - 1]
    }
  }
}

export interface Interval {
  min: number;
  max: number;
}
