








import { Component, Prop, Vue } from 'vue-property-decorator'
import Vital from '@/application/models/Vital'
import { VitalSignType } from '@/application/models/Thresholds'
import ApexCharts from 'apexcharts'

@Component({})
export default class LineChart extends Vue {
  @Prop({ required: true })
  type!: 'puls' | 'oxygen' | 'temp'

  @Prop()
  min!: number

  @Prop()
  max!: number

  private data = {}
  private settings = {}

  created () {
    this.settings = this.getSettings()
    this.data = this.getData()
    console.log('created')

    this.$nextTick(() => {
      this.updateChart()
      console.log('chart is updated')
    })
  }

  updateChart () {
    const chart = (this.$refs.chart as unknown as ApexCharts)
    chart.updateSeries(this.getData(), true)
    window.setInterval(() => {
      chart.updateSeries(this.getData(), true)
      console.log('intervall')
    }, 1000)
  }

  updated () {
    console.log('update')
  }

  private getData (): Array<any> {
    const returningData: Array<{ x: number; y: Date }> = []
    /**
    // switch (this.type) {
    //   case 'oxygen': {
    //     returningData = this.$store.state.vitals.map((v: Vital) => v.oxygenSaturation)
    //     break
    //   }
    //   case 'puls': {
    //     returningData = this.$store.state.vitals.map((v: Vital) => {
    //       return {
    //         x: new Date(v.timestamp),
    //         y: v.pulseRate
    //       }
    //     })
    //     break
    //   }
    //   case 'temp': {
    //     returningData = this.$store.state.vitals.map((v: Vital) => v.bodyTemperature)
    //     break
    //   }
    //   default: {
    //     returningData = this.$store.state.vitals.map((v: Vital) => v.oxygenSaturation)
    //     break
    //   }
    // }
    **/
    return [{
      data: [1, 2, 3]
      // data: returningData.slice(returningData.length - 11, returningData.length - 1)
    }]
  }

  private getSettings () {
    return {
      chart: {
        id: 'vuechart-example',
        animations: {
          enabled: true,
          easing: 'linear',
          dynamicAnimation: {
            speed: 1000
          }
        },
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      colors: [this.colorByTypeAndMode()],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      markers: {
        size: 0
      },
      xaxis: this.getMinMaxXAxis(),
      yaxis: this.getMinMaxYAxis(),
      annotations: this.getMinMaxThresholds()
    }
  }

  private getMinMaxXAxis () {
    const returningOBJ = {
      labels: {
        show: true
      },
      type: 'datetime'
      // range: 10
    }

    return returningOBJ
  }

  private getMinMaxYAxis () {
    const minMax = {
      min: 0,
      max: 100
    }
    switch (this.type) {
      case 'puls':
        minMax.min = this.getThreshold(VitalSignType.PULSE_RATE).min - 10
        minMax.max = this.getThreshold(VitalSignType.PULSE_RATE).max * 1.55
        break
      case 'oxygen':
        minMax.min = this.getThreshold(VitalSignType.OXYGEN_SATURATION).min - 2
        minMax.max = this.getThreshold(VitalSignType.OXYGEN_SATURATION).max + 2
        break
      case 'temp':
        minMax.min = this.getThreshold(VitalSignType.BODY_TEMPERATURE).min - 2
        minMax.max = this.getThreshold(VitalSignType.BODY_TEMPERATURE).max + 2
        break
    }
    return this.$store.state.appSettings.fixYAxis ? minMax : {}
  }

  private getMinMaxThresholds () {
    const annotations = {
      yaxis: [
        {
          y: 50,
          y2: 0,
          borderColor: '#E57373',
          fillColor: '#E57373'
        },
        {
          y: 200,
          y2: 80,
          borderColor: '#E57373',
          fillColor: '#E57373'
        }
      ]
    }
    switch (this.type) {
      case 'puls':
        annotations.yaxis[0].y = this.getThreshold(VitalSignType.PULSE_RATE).min
        annotations.yaxis[1].y2 = this.getThreshold(VitalSignType.PULSE_RATE).max
        break
      case 'oxygen':
        annotations.yaxis[0].y = this.getThreshold(VitalSignType.OXYGEN_SATURATION).min
        annotations.yaxis[1].y2 = this.getThreshold(VitalSignType.OXYGEN_SATURATION).max
        break
      case 'temp':
        annotations.yaxis[0].y = this.getThreshold(VitalSignType.BODY_TEMPERATURE).min
        annotations.yaxis[1].y2 = this.getThreshold(VitalSignType.BODY_TEMPERATURE).max
        break
    }
    return annotations
  }

  private getThreshold (type: VitalSignType) {
    return this.$store.state.thresholds.getThreshold(type)
  }

  private colorByTypeAndMode (): string {
    let color
    switch (this.type) {
      case 'puls':
        color = this.$vuetify.theme.themes.light.secondary
        break
      case 'oxygen':
        color = this.$vuetify.theme.themes.light.accent
        break
      case 'temp':
        color = this.$vuetify.theme.themes.light.primary
        break
      default:
        color = '#106EBE'
        break
    }
    return color!.toString()
  }
}
